<template>
  <Listbox v-slot="{ open }" v-model="selectValues" :multiple="multiple" class="font-medium">
    <Float as="div" class="relative" position="bottom" floating-as="template">
      <ListboxButton
        :class="[
          'flex items-center justify-between',
          'h-8',
          'select-none whitespace-nowrap text-xs',
          'bg-gray-800/50  focus:border-blue-700 focus:outline-none',
          'rounded border border-solid border-gray-700',
          'group focus:outline-none',
          buttonWidthClass,
          stickyPlaceholder ? 'pr-2' : 'px-2'
        ]"
      >
        <div v-if="stickyPlaceholder" class="flex items-center">
          <div
            :class="[
              'z-0 flex h-[30px] select-none items-center rounded-l px-2.5 text-xs text-gray-400',
              'mr-2 border-gray-700 bg-gray-800'
            ]"
          >
            {{ placeholder }}
          </div>
        </div>
        <div class="flex w-full justify-between">
          <span
            v-if="selectLabels.length > 1"
            :class="[open ? 'text-white' : 'text-gray-200', 'truncate font-medium group-hover:text-white']"
          >
            {{
              selectLabels
                .slice(0, displayCount)
                ?.map(i => i.label)
                .join(', ')
            }}
            <span v-if="selectLabels.length - displayCount > 0"> + {{ selectLabels.length - displayCount }} </span>
          </span>
          <span
            v-else-if="selectLabels.length == 1"
            :class="[open ? 'text-white' : 'text-gray-200', 'font-medium group-hover:text-white']"
          >
            <div class="flex items-center space-x-1">
              <div class="flex-shrink-0">
                <img
                  v-if="selectLabels?.[0]?.icon"
                  :src="selectLabels?.[0]?.icon"
                  :class="['h-4 w-4', selectLabels?.[0]?.iconClass]"
                />
              </div>
              <span>
                {{ selectLabels?.[0]?.label }}
              </span>
            </div>
          </span>
          <span v-else-if="!stickyPlaceholder" class="text-gray-400">
            {{ placeholder }}
          </span>
          <ChevronUpIcon v-if="open" class="ml-1 h-3.5 w-3.5 align-middle text-gray-200 group-hover:text-white" />
          <ChevronDownIcon v-else class="ml-1 h-3.5 w-3.5 align-middle text-gray-200 group-hover:text-white" />
        </div>
      </ListboxButton>

      <ListboxOptions
        :class="[
          'top-1 antialiased backdrop-blur-md',
          'border border-solid border-gray-700 bg-gray-800/80 focus:outline-none',
          'rounded',
          'text-xs',
          widthClass,
          dropdownWidthClass
        ]"
      >
        <div class="w-full rounded-tl-lg rounded-tr-lg p-1">
          <BaseSearchbox @keydown="keydown" v-model="search" :loader="loading" />
        </div>
        <div
          v-if="selectedOptions.length && showClear"
          class="cursor-pointer p-2 text-xs text-gray-400"
          @click="clearAll"
        >
          Clear All
        </div>
        <template v-if="showSelected && selectedOptions.length">
          <div class="max-h-28 overflow-auto">
            <ListboxOption
              v-for="option in selectedOptions"
              :key="option.label"
              v-slot="{ disabled, selected }"
              :value="option.id"
              :disabled="option.disabled"
              as="template"
            >
              <li
                :class="[
                  disabled ? 'cursor-default text-gray-500' : 'cursor-pointer text-gray-300',
                  'group relative select-none bg-gray-700/50 py-2 pl-7 duration-100'
                ]"
              >
                <span
                  :class="[multiple ? 'pl-1.5' : 'pl-2', 'absolute inset-y-0 left-0 flex items-center']"
                  aria-hidden="true"
                >
                  <span v-if="!selected" class="h-3 w-3"></span>
                  <CheckIcon v-else-if="multiple && selected" class="h-4 w-4 p-px" />
                  <span
                    v-else
                    :class="[
                      'h-3 w-3 rounded-full border border-solid border-blue-600 bg-gray-100',
                      selected ? 'border-4' : ''
                    ]"
                  />
                </span>
                <div class="flex items-center space-x-2">
                  <div class="flex-shrink-0">
                    <img v-if="option.icon" :src="option.icon" :class="['h-4 w-4', option.iconClass]" />
                  </div>
                  <span>
                    {{ option.label }}
                  </span>
                </div>
              </li>
            </ListboxOption>
          </div>
        </template>
        <BaseDivider />
        <div class="max-h-64 overflow-auto">
          <template v-if="loading || !currentOptionsList.length">
            <div class="my-6 flex items-center justify-center">
              <BaseLoader v-if="loading" />
              <div v-else-if="emptySearch" class="text-gray-400">Type what you are looking for</div>
              <div v-else class="text-gray-400">No results found</div>
            </div>
          </template>
          <template v-else>
            <ListboxOption
              v-for="option in currentOptionsList"
              :key="option.label"
              v-slot="{ active, disabled, selected }"
              :value="option.id"
              as="template"
            >
              <li
                :class="[
                  active ? 'bg-gray-700/50' : '',
                  disabled ? 'cursor-default text-gray-500' : 'cursor-pointer text-gray-300',
                  'group relative select-none py-2 pl-7 duration-100'
                ]"
              >
                <span
                  :class="[multiple ? 'pl-1.5' : 'pl-2', 'absolute inset-y-0 left-0 flex items-center']"
                  aria-hidden="true"
                >
                  <span v-if="!selected" class="h-4 w-4"></span>
                  <CheckIcon v-else-if="multiple && selected" class="h-4 w-4 p-px" />
                  <span
                    v-else
                    :class="[
                      'h-3 w-3 rounded-full border border-solid border-blue-600 bg-gray-100',
                      selected ? 'border-4' : ''
                    ]"
                  />
                </span>
                <div class="flex items-center space-x-1">
                  <div class="flex-shrink-0">
                    <img v-if="option.icon" :src="option.icon" :class="['h-4 w-4', option.iconClass]" />
                  </div>
                  <span class="flex space-x-2">
                    <span>{{ option.label }}</span>
                    <span v-if="subText" class="uppercase text-gray-400">{{ option[subText] }}</span>
                  </span>
                </div>
              </li>
            </ListboxOption>
          </template>
        </div>
      </ListboxOptions>
    </Float>
  </Listbox>
</template>

<script setup>
import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';
import { Float } from '@headlessui-float/vue';
import { ref, computed, watch, onMounted } from 'vue';
import { ChevronDownIcon, ChevronUpIcon, CheckIcon } from '@heroicons/vue/24/solid';
import { Listbox, ListboxButton, ListboxOptions, ListboxOption, ListboxLabel } from '@headlessui/vue';
import useEmitter from '@/composeables/emitter';

const emitter = useEmitter();

const props = defineProps({
  modelValue: { type: [Array, String, Number], required: true },
  multiple: { type: Boolean, default: false },
  placeholder: { type: String, default: '' },
  widthClass: { type: String, default: '' },
  buttonWidthClass: { type: String, default: '' },
  dropdownWidthClass: { type: String, default: 'w-48' },
  displayCount: { type: Number, default: 3 },
  showClear: { type: Boolean, default: true },
  showSelected: { type: Boolean, default: false },
  asyncOptions: { type: Function, required: true },
  preSelectedOptions: { type: Array, required: false },
  subText: { type: String, default: null },
  stickyPlaceholder: { type: Boolean, default: false }
});
const emit = defineEmits(['update:modelValue']);

//ASYNC OPTIONS
const search = ref('');
const loading = ref(false);
const optionsCache = ref([]);
const currentOptionsList = ref([]);
const emptySearch = ref(true);
const getOptions = debounce(async () => {
  loading.value = true;
  if (search.value) emptySearch.value = false;
  const response = await props.asyncOptions(search.value);
  currentOptionsList.value =
    uniqBy(
      response?.filter(x => x.id),
      'id'
    ) || [];
  optionsCache.value = uniqBy([optionsCache.value, currentOptionsList.value].flat(), 'id').filter(x => x);
  loading.value = false;
}, 500);
watch(search, () => {
  if (!search.value.length) emptySearch.value = true;
  getOptions();
});
onMounted(async () => {
  search.value = props.modelValue;
  await getOptions();
  if (props.preSelectedOptions) {
    optionsCache.value = props.preSelectedOptions;
  }
  emitter.$on('clear:search', () => {
    search.value = '';
    selectValues.value = props.multiple ? [] : '';
  });

  emitter.$on('fetch:data', () => {
    getOptions();
  });
});

function keydown(e) {
  if (e.keyCode === 32) {
    e.stopPropagation();
  }
}

//SELECTED VALUES
const selectValues = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value)
});
const selectLabels = computed(() => {
  const selectedIds = props.multiple ? props.modelValue : props.modelValue ? [props.modelValue] : [];
  let option = [...new Set(selectedIds.map(id => optionsCache.value.find(option => option?.id == id)))];
  return option.filter(x => x);
});
const selectedOptions = computed(() => {
  if (!selectLabels.value.length) return [];
  const options = optionsCache.value.filter(o => selectLabels.value.map(i => i?.id).includes(o.id));
  return options.filter((v, i, a) => a.findIndex(v2 => v2.id === v.id) === i); //remove duplicates
});
const clearAll = e => {
  e.preventDefault();
  search.value = '';
  selectValues.value = props.multiple ? [] : '';
  return e;
};
</script>
